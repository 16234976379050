import { useAuthenticationContext } from '@/context/authentication'
import PageLoader from '@components/pageLoader'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
export const LogOut: React.FC = () => {
    const { signOut } = useAuthenticationContext()
    const navigator = useNavigate()
    useEffect(() => {
        signOut(() => {
            navigator('/')
        })
    }, [])
    return <PageLoader />
}
