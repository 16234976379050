import { message } from 'antd'
import React, { ReactNode } from 'react'
import Clipboard from 'react-copy-to-clipboard'
export const CopyToClipboard: React.FC<Props> = ({ children, content }) => {
    const [messageApi, contextHolder] = message.useMessage()
    return (
        <>
            {contextHolder}
            <Clipboard
                text={content}
                onCopy={() =>
                    messageApi.info({
                        content: 'Copied',
                        style: {
                            color: '#000',
                        },
                    })
                }
            >
                {children}
            </Clipboard>
        </>
    )
}
interface Props {
    children: ReactNode
    content: string
}
