import React from 'react'
import AuthLayout from '@/modules/Layout/auth'
import NotPageFound from '@/pages/errors/no-page'
import { Navigate, useRoutes } from 'react-router-dom'
import MainLayout from '@/modules/Layout/main'
import LoginPage from '@/pages/auth/signin/login'
import Page404 from '@/pages/errors/404'
import { LogOut } from '@/pages/auth/logout'
import { DashboardPage } from '@/pages/main/dashboard'
import WalletPage from '@/pages/main/wallet'
import CardPage from '@/pages/main/card'
import WalletBatchPage from '@/pages/main/wallet/batch'
import { SettingsPage } from '@/pages/main/customer/setting'
import { EditSettingsPage } from '@/pages/main/customer/setting/edit'
import { EditServerPage } from '@/pages/main/customer/server/edit'
import { ServerPage } from '@/pages/main/customer/server'
import TabsLayout from '@/modules/Layout/tabs'

const AppRoutes = () => {
    const appRoutes = useRoutes([
        {
            path: '/auth',
            element: <AuthLayout />,
            children: [
                { path: 'login', element: <LoginPage /> },
                {
                    path: '',
                    element: <Navigate to={'/auth/login'} />,
                },
                {
                    path: '*',
                    element: <Navigate to={'/auth/login'} />,
                },
            ],
        },
        {
            element: <MainLayout />,
            children: [
                {
                    path: 'dashboard',
                    element: <DashboardPage />,
                },
                {
                    path: 'wallet',
                    children: [
                        {
                            path: '',
                            element: <WalletPage />,
                        },
                        {
                            path: 'batch/:batchId/printJson',
                            element: <WalletBatchPage />,
                        },
                        {
                            path: ':walletId/cards',
                            element: <CardPage />,
                        },
                    ],
                },

                {
                    path: 'card',
                    element: <CardPage />,
                },
                {
                    path: 'customer',
                    element: <TabsLayout />,
                    children:[
                        {
                            path:'setting',
                            children:[
                                {
                                
                                    path:'',
                                    element: <SettingsPage />,
                                
                                },
                                {
                                    path:'edit',
                                    element: <EditSettingsPage />,
                                },
                            ]
                        },
                        {
                            path:'server',
                            children:[
                                {
                                
                                    path:'',
                                    element: <ServerPage />,
                                
                                },
                                {
                                    path:'edit',
                                    element: <EditServerPage />,
                                },
                            ]
                        }
                    ]
                },

                { path: '*', element: <Navigate to='/dashboard' /> },
            ],
        },
        {
            path: 'logout',
            element: <LogOut />,
        },
        {
            path: '/not-found',
            element: <NotPageFound />,
        },
        {
            path: '/404',
            element: <Page404 />,
        },
        {
            path: '*',
            element: <Navigate to={'/not-found'} />,
        },
    ])
    return <>{appRoutes}</>
}

export default AppRoutes
