import { message, notification } from 'antd'
import { MessageInstance } from 'antd/es/message/interface'
import { NotificationInstance } from 'antd/es/notification/interface'
import React, { PropsWithChildren, createContext, useContext } from 'react'

export interface NotificationContextData {
    notificationApi: NotificationInstance | null
    messageApi: MessageInstance | null
}

const notificationContextDefaultValue: NotificationContextData = {
    notificationApi: null,
    messageApi: null,
}
export const notificationContext = createContext<NotificationContextData>(notificationContextDefaultValue)

export const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [notificationApi, contextHolderN] = notification.useNotification()
     const [messageApi, contextHolderM] = message.useMessage()
    return (
        <notificationContext.Provider value={{ notificationApi, messageApi }}>
            {contextHolderM}
            {contextHolderN}
            {children}
        </notificationContext.Provider>
    )
}

export const useNotificationContext = () => {
    return useContext(notificationContext)
}
