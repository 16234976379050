import { getWallets } from '@api/wallet'
import { CopyToClipboard } from '@components/CopyToClipboard'
import PageLoader from '@components/pageLoader'
import { Button, Card, Empty } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

interface PrintWallet {
    lighningAddress: string
    pin: string
    balanceUrl: string
}
const WalletBatchPage: React.FC = () => {
    const { batchId } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [wallets, setWallets] = useState<PrintWallet[]>([])

    useEffect(() => {
        setIsLoading(true)
        const fetchWallets = async () => {
            const { records } = await getWallets({
                page: 1,
                per_page: 10000,
                batch_id: batchId!,
            })
            const parsed = records.map((x) => ({
                pin: x.pin,
                lighningAddress: x!.ln_address ?? `${x.username}@tiankii.app`,
                balanceUrl: `https://go.tiankii.app/${x.username}/balance`,
            }))
            setWallets(parsed)
        }
        fetchWallets().finally(() => setIsLoading(false))
    }, [])
    if (isLoading) return <PageLoader />
    return (
        <Card
            style={{
                height: !isLoading && wallets.length == 0 ? 'auto' : '90vh',
                minWidth: window.innerWidth >= 500 ? 500 : window.innerWidth,
            }}
            bodyStyle={{ overflow: 'auto', maxHeight: '100%' }}
        >
            {wallets.length == 0 ? (
                <Empty description={'no related wallets to this batch'} />
            ) : (
                <>
                    <CopyToClipboard content={JSON.stringify(wallets)}>
                        <Button style={{ width: '100%' }}>Copy</Button>
                    </CopyToClipboard>
                    <ul>
                        {wallets.map((x, index) => (
                            <li key={index}>
                                <pre>{JSON.stringify(x, null, 2)}</pre>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </Card>
    )
}
export default WalletBatchPage
