import { useNfcContext } from '@/context/nfc'
import { useNotificationContext } from '@/context/notification'
import { useAppDispatch } from '@/store/hook'
import { generalActions } from '@/store/slice'
import { createCard } from '@api/card'
import { ICard } from '@api/interfaces/card'
import { IWallet } from '@api/interfaces/wallet'
import { Button, Drawer, DrawerProps, Form, Input, Row, Space } from 'antd'
import React, { useEffect } from 'react'
import { MdNfc } from 'react-icons/md'

interface DialogCardProps extends DrawerProps {
    wallet: IWallet
    onFinishCreating: (card: ICard) => any
}

const CreateCardDialogForm: React.FC<DialogCardProps> = ({ wallet, onFinishCreating, ...props }) => {
    const dispatch = useAppDispatch()

    const [form] = Form.useForm()

    const { messageApi } = useNotificationContext()
    const { isStarted, isSupported, setIsStarted } = useNfcContext()

    const readerAbortController = new AbortController()

    readerAbortController.signal.onabort = () => {
        console.log('All NFC Read operations have been aborted.')
    }

    const genRanHex = (size: number) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')

    const onFinish = async (values: any) => {
        try {
            dispatch(
                generalActions.setLoading({
                    isLoading: true,
                    mode: 'notification',
                    loadingContent: 'Creating card',
                }),
            )
            const card = await createCard(
                {
                    card_name: values.name,
                    uid: values.UID,
                    k0: genRanHex(32),
                    k1: genRanHex(32),
                    k2: genRanHex(32),
                    wallet_id: wallet.id,
                },
            )
            dispatch(generalActions.setLoading({ isLoading: false }))
            onFinishCreating(card)
        } catch (error: any) {
            dispatch(generalActions.setError({ errorMessage: error.message, mode: 'notification' }))
        }
    }

    const onReset = () => {
        form.resetFields()
    }
    const turnOnNFC = async () => {
        if (!isSupported) {
            messageApi?.error({ content: 'NFC reader is not supported here' })
            return
        }
        if (isStarted) {
            messageApi?.info({ content: 'NFC reader is started' })
            return
        }
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const ndef: NDEFReader = new window.NDEFReader()
            await ndef.scan({ signal: readerAbortController.signal })

            messageApi?.info({ content: 'Tap your NFC card to copy UID card here.' })

            setIsStarted(true)

            ndef.onreadingerror = () => {
                messageApi?.error({ content: 'Error reading boltcard. Refresh page' })
                readerAbortController.abort()
            }
            ndef.onreading = async function ({ serialNumber }: any) {
                form.setFieldValue('UID', serialNumber.toUpperCase().replaceAll(':', ''))
                messageApi!.info({ content: 'NFC card read successfully' })
                form.submit()
            }
        } catch (e: any) {
            messageApi?.error({ content: 'Error! Scan failed to start' })
        }
    }

    useEffect(() => {
        if (isSupported && !isStarted) {
            turnOnNFC()
        }
        return () => {
            readerAbortController.abort()
            setIsStarted(false)
        }
    }, [])
    return (
        <Drawer placement={'top'} height={'auto'} width={400} title='Creation Card' {...props}>
            <Form
                form={form}
                layout='vertical'
                name='card-form'
                onFinish={onFinish}
                style={{ width: '100%' }}
                initialValues={{
                    name: `${wallet?.username}01`,
                }}
            >
                <Form.Item name='name' label='Name' rules={[{ required: true }, { min: 4, max: 20 }]}>
                    <Input />
                </Form.Item>
                <Row align={'middle'}>
                    <Form.Item name='UID' label='Card UID' rules={[{ required: true }]} style={{ width: '90%' }}>
                        <Input />
                    </Form.Item>
                    <Button style={{ width: '10%', padding: 0, marginTop: 5 }} onClick={turnOnNFC}>
                        <MdNfc size={20} />
                    </Button>
                </Row>

                <Form.Item>
                    <Space>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                        <Button htmlType='button' onClick={onReset}>
                            Reset
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
export default CreateCardDialogForm
