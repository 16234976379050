import React from 'react'
import { Tabs, Card, Typography, Layout, Col, Row  } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import { SolutionOutlined, WalletOutlined } from '@ant-design/icons'

const TabsLayout: React.FC = () => {
    const navigate = useNavigate()
    const { Title } = Typography;
    const items = [
        {
            title: 'General Settings',
            route: 'setting',
            icon:<SolutionOutlined/>
        },
        {
            title: 'Server Settings',
            route: 'server',
            icon:<WalletOutlined/>
        }
    ]
    const handleTabChange = (key: string) => {
        navigate(key)
    }
    return (
        <Card
            className='ant-hide'
            style={{ width: '100%', minHeight: '80vh', height: 'auto' }}
            bodyStyle={{ padding: 25 }}
        >
            <Title level={3} >Customer Settings</Title>
            <Layout.Content >
                <Row justify="start" >
                    <Col style={{marginRight:'50px', marginBottom:'20px'}} className='tabs'>
                        <Tabs
                            className='tabs tabs-items'
                            size='large'
                            tabPosition='left'
                            activeKey={location.pathname.split('/')[2]}
                            items={items.map((item, i) => {
                                return {
                                    label: (
                                        <>
                                            {item.icon}
                                            {item.title}
                                        </>
                                        ),
                                    key: item.route,
                                }
                            })}
                            onChange={handleTabChange}
                        />
                    </Col>
                    <Col flex={2} style={{maxWidth:'888px'}}>
                        <Outlet />
                    </Col>
                </Row>
            </Layout.Content>
        </Card>
    )
}

export default TabsLayout
