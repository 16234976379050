import axios, { InternalAxiosRequestConfig } from 'axios'

const CardInstance = axios.create({
    baseURL: process.env.REACT_APP_BOLTCARD_API,
})

const WalletInstance = axios.create({
    baseURL: process.env.REACT_APP_WRAPPER_WALLET_API,
})
async function injectInterceptor(req: InternalAxiosRequestConfig) {
    try {
        const auth = JSON.parse(localStorage.getItem('auth')!)
        req.headers.Authorization = `Bearer ${auth.token}`
        return req;
    } catch (error) {
        /* empty */
    }
    return req
}
const handleError = (error: any) => {
    let errorMessage = error?.response?.data?.message || error.message
    errorMessage = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage
    throw new Error(errorMessage)
}

CardInstance.interceptors.request.use(injectInterceptor)
WalletInstance.interceptors.request.use(injectInterceptor)
CardInstance.interceptors.response.use(null, handleError)
WalletInstance.interceptors.response.use(null, handleError)

export { CardInstance, WalletInstance }
