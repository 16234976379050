import React from 'react'

import { useAuthenticationContext } from '@/context/authentication'
import { Layout } from 'antd'
import { Navigate, Outlet } from 'react-router-dom'
import '@/styles/pages/auth.less'

const AuthLayout: React.FC = () => {
    const { auth, customer } = useAuthenticationContext()

    if (auth && customer) return <Navigate to={'/dashboard'} replace />

    return (
        <Layout>
            <Layout.Content style={{minHeight: '100vh'}}>
                <Outlet />
            </Layout.Content>
        </Layout>
    )
}

export default AuthLayout
