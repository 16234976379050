import { useAuthenticationContext } from '@/context/authentication'
import {
    CreditCardOutlined,
    HomeOutlined,
    LogoutOutlined,
    SettingOutlined,
    UserOutlined,
    WalletOutlined,
} from '@ant-design/icons'

import { Divider, Layout, Menu, MenuProps, Row } from 'antd'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useLocation, useNavigate } from 'react-router-dom'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return { key, icon, children, label } as MenuItem
}

export const CustomSider = () => {
    const [collapsed, setCollapsed] = useState(false)

    return (
        <Layout.Sider
            collapsible
            width={isMobile ? 200 : 260}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            style={{
                background:
                    ' linear-gradient(to bottom, rgba(29,5,96,1) 0%, rgba(29,5,96,1) 28%, rgba(99,0,255,1) 100%)',
                boxShadow:
                    ' 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 3px 4px rgba(0, 0, 0, 0.14)',
            }}
        >
            <SiderContent collapsed={collapsed} />
        </Layout.Sider>
    )
}
export const SiderContent: React.FC<{ collapsed?: boolean; collapse?: () => any }> = ({ collapsed, collapse }) => {
    const history = useLocation()
    const navigator = useNavigate()

    return (
        <>
            <SiderProfile collapsed={collapsed} />
            <Menu
                theme='dark'
                mode='inline'
                defaultSelectedKeys={[history.pathname.split('/')[1] || 'dashboard']}
                style={{ background: 'transparent', marginTop: 0 }}
                onSelect={(e) => {
                    collapse && collapse()
                    navigator(`/${e.key}`)
                }}
                items={[
                    getItem('Dashboard', 'dashboard', <HomeOutlined />),
                    getItem('Wallet', 'wallet', <WalletOutlined />),
                    getItem('Card', 'card', <CreditCardOutlined />),
                ]}
            />
        </>
    )
}
const SiderProfile: React.FC<{ collapsed?: boolean }> = ({ collapsed }) => {
    const { customer, signOut } = useAuthenticationContext()
    const navigator = useNavigate();
    return (
        <Row style={{ height: collapsed ? 120 : 200 }} justify={'center'} align={'middle'}>
            <Row align={'middle'} justify={'center'} style={{ height: collapsed ? 50 : 100 }}>
                <img
                    alt='Logo'
                    src={`/images/logo-white${collapsed ? '-symbol' : ''}.png`}
                    width={collapsed ? 50 : 170}
                    height={collapsed ? 50 : 70}
                />
            </Row>
            {!collapsed && customer && (
                <Row>
                    {customer.name && (
                        <span style={{ color: '#fff', marginLeft: 20, marginBottom: -5 }}>{customer.name}</span>
                    )}
                    <Menu
                        style={{ width: '100%', background: 'transparent' }}
                        theme='dark'
                        onClick={(e) => {
                            if (e.key == 'customer/setting') navigator(`/${e.key}`)
                            if (e.key == 'logout') signOut()
                        }}
                        items={[
                            getItem(customer.owner_name, 'name', null, [
                                getItem('Profile', 'profile', <UserOutlined />),
                                getItem('Settings', 'customer/setting', <SettingOutlined />),
                                getItem('Logout', 'logout', <LogoutOutlined />),
                            ]),
                        ]}
                    />
                </Row>
            )}
            <Divider style={{ margin: '10px 0', background: '#fff' }} />
        </Row>
    )
}
