/* eslint-disable camelcase */
import axios from 'axios'
import { ICustomer } from './interfaces/customer'

const Axios = axios.create({
    baseURL: process.env.REACT_APP_WRAPPER_WALLET_API,
})

export const updateCustomer = async (id: string, token: string, data: Partial<ICustomer>) => {
    delete data.location;
    const res = await Axios.patch(`/customer/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return res.data
}
export default { updateCustomer }
