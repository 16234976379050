import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type modeType = 'page' | 'modal' | 'notification'
interface generalState {
    errorMessage: string
    isLoading: boolean
    mode?: modeType
    title?: string
    loadingContent?: string
    source?: string
    redirect?: string
    detroyAll?: boolean
}

type LoadingProps = Omit<generalState, 'errorMessage'>
type ErrorProps = Omit<Omit<generalState, 'isLoading'>, 'loadingContent'>
const initialState = { mode: 'page' } as generalState

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setError(state, { payload }: PayloadAction<ErrorProps>) {
            state.errorMessage = payload.errorMessage
            state.mode = payload.mode
            state.source = payload.source
            state.isLoading = false
            state.detroyAll = true
        },
        setLoading(state, { payload }: PayloadAction<LoadingProps>) {
            state.isLoading = payload.isLoading
            state.loadingContent = payload.loadingContent
            state.mode = payload.mode
            state.source = payload.source
            state.redirect = payload.redirect
            state.detroyAll = payload.detroyAll
        },
        reset: () => initialState,
    },
})

export default generalSlice.actions
