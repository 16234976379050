import { CloseOutlined } from '@ant-design/icons'
import { ICard } from '@api/interfaces/card'
import QRCode from '@components/QrCode'
import { Button, Drawer, DrawerProps, Radio, Row, Typography } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'

interface DialogCardProps extends DrawerProps {
    card: ICard
    creation?: boolean
}

const DialogCard: React.FC<DialogCardProps> = ({ card, creation = true, ...props }) => {
    const [action, setAction] = useState('create')
    const [qrCodeData, setQrCodeData] = useState('')

    const height = useMemo(() => {
        if (innerHeight > 700) return 'auto'
        return '100vh'
    }, [innerHeight])

    useEffect(() => {
        if (card) {
            if (action == 'create') {
                setQrCodeData(`https://boltcard.tiankii.app/v1/auth?code=${card.otp}`)
            } else {
                setQrCodeData(
                    JSON.stringify({
                        action: 'wipe',
                        k0: card.k0,
                        k1: card.k1,
                        k2: card.k2,
                        k3: card.k1,
                        k4: card.k2,
                        uid: card.uid,
                        version: 1,
                    }),
                )
            }
        }
        return () => setQrCodeData('')
    }, [action, card])

    return (
        <Drawer
            placement={'top'}
            height={height}
            width={400}
            headerStyle={{ display: 'none' }}
            bodyStyle={{ padding: '5px 10px 20px' }}
            style={{ backgroundColor: 'rgb(56, 95, 115)', color: '#fff' }}
            {...props}
        >
            <Row justify={'end'} align={'middle'} style={{ height: 55 }}>
                <Typography.Title level={4} style={{ margin: 0, width: '80%', textAlign: 'center', color: '#fff' }}>
                    {action == 'wipe' ? 'Reset Card' : 'Link Card'}
                </Typography.Title>
                <Button
                    type='text'
                    icon={<CloseOutlined />}
                    size={'large'}
                    style={{ color: '#fff' }}
                    onClick={props.onClose}
                />
            </Row>

            <Row justify={'center'}>
                <div style={{ padding: 15, borderRadius: 12, backgroundColor: '#fff' }}>
                    <QRCode content={qrCodeData} />
                </div>

                {creation && (
                    <Radio.Group
                        options={['create', 'wipe']}
                        onChange={(e) => setAction(e.target.value)}
                        value={action}
                        optionType='button'
                        style={{ marginTop: 10 }}
                        buttonStyle='solid'
                    />
                )}
                <p style={{ wordBreak: 'break-all', fontSize: '1rem' }}>
                    <strong>Name:</strong> {card?.card_name}
                    <br />
                    <strong>UID:</strong> {card?.uid}
                    <br />
                    <strong>External ID:</strong> {card?.external_id}
                    <br />
                    <strong>Lock key (K0):</strong> {card?.k0}
                    <br />
                    <strong>Meta key (K1 &amp; K3):</strong>
                    {card?.k1}
                    <br />
                    <strong>File key (K2 &amp; K4):</strong>
                    {card?.k2}
                    <br />
                </p>
            </Row>
            <Row justify={'end'}>
                <Button onClick={props.onClose}>Close</Button>
            </Row>
        </Drawer>
    )
}
export default DialogCard
