import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { generalSlice } from './slice/general'

const store = configureStore({
    reducer: {
        [generalSlice.name]: generalSlice.reducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
})

export type AppState = ReturnType<(typeof store)['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>
export type AppDispatch = typeof store.dispatch

export default store
