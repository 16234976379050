import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'

export interface nfcContextData {
    isSupported: boolean
    isStarted: boolean
    setIsStarted: (value: boolean) => void
}

const nfcContextDefaultValue: nfcContextData = {
    isSupported: false,
    isStarted: false,
    setIsStarted: () => null,
}

export const nfcContext = createContext<nfcContextData>(nfcContextDefaultValue)

export const NfcProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [isStarted, setIsStarted] = useState(false)
    const [isSupported, setIsSupported] = useState(false)

    useEffect(() => {
        const isSupported = 'NDEFReader' in window
        setIsSupported(isSupported)
    }, [])

    return (
        <nfcContext.Provider
            value={{
                isStarted,
                isSupported,
                setIsStarted,
            }}
        >
            {children}
        </nfcContext.Provider>
    )
}

export const useNfcContext = () => {
    return useContext(nfcContext)
}
