import { useAuthenticationContext } from '@/context/authentication'
import { useNotificationContext } from '@/context/notification'
import { useAppDispatch, useAppSelector } from '@/store/hook'
import { generalActions } from '@/store/slice'
import React, { PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ErrorHandlerLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch()
    const navigator = useNavigate()

    const { error, setError } = useAuthenticationContext()
    const { notificationApi } = useNotificationContext()
    const { errorMessage, title, mode } = useAppSelector((state) => state.general)

    useEffect(() => {
        if (error && notificationApi) {
            notificationApi.error({
                message: 'Login error',
                description: error,
                duration: 10,
                onClose() {
                    setError('')
                },
            })
        }
        return () => setError('')
    }, [error])

    useEffect(() => {
        if (errorMessage) {
            if (mode == 'page') navigator('/404', { replace: true })
            else {
                if (notificationApi) {
                    notificationApi.error({
                        message: title || 'Error',
                        description: errorMessage,
                        duration: 10,
                        onClose() {
                            dispatch(generalActions.setError({ errorMessage: '' }))
                        },
                    })
                }
            }
        }
    }, [errorMessage])

    return <>{children}</>
}

export default ErrorHandlerLayout
