import { CardInstance } from './axios'
import { CardResponse, ICard } from './interfaces/card'

export interface CreateCardPayload {
    card_name: string
    wallet_id: string
    k0: string
    k1: string
    k2: string
    uid: string
}

interface CardProps {
    page: number
    per_page: number
    card_name?: string
    wallet_id?: string
    sorter?: string
}
export const getCards = async ({ ...props }: CardProps) => {
    const res = await CardInstance.get<CardResponse>('/card', {
        // eslint-disable-next-line camelcase
        params: { ...props, include_metadata: true },
    })
    return res.data
}
export const createCard = async (data: CreateCardPayload) =>
    (await CardInstance.post<ICard & { authUrl: string }>('/card', data)).data

export const updateCard = async (cardId: string, data: Partial<ICard>) =>
    (await CardInstance.put(`/card/${cardId}`, data)).data

export const removeCard = async (cardId: string) => (await CardInstance.delete(`/card/${cardId}`)).data

export const enableCard = async (cardId: string, enable: boolean) =>
    (
        await CardInstance.patch(`/card/enable/${cardId}`, null, {
            params: { enable },
        })
    ).data

export default { createCard, getCards, removeCard, enableCard }
