import React, { useState } from 'react'
import { Button, Card, Input, Typography } from 'antd'
import { useAuthenticationContext } from '@/context/authentication'
import { LogoutOutlined } from '@ant-design/icons'

const LoginPage: React.FC = () => {
    const { signIn } = useAuthenticationContext()
    const [value, setValue] = useState('')

    return (
        <Card hoverable>
            <Typography.Title>Input Your Token</Typography.Title>
            <Input
                placeholder='Input your token'
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onPressEnter={() => signIn(value)}
            />
            <Button style={{ marginTop: 20 }} type='primary' icon={<LogoutOutlined />} onClick={() => signIn(value)}>
                Login
            </Button>
        </Card>
    )
}

export default LoginPage
