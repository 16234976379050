import React, { useState } from 'react'
import { Button, Drawer, Grid, Layout } from 'antd'

import { useAuthenticationContext } from '@/context/authentication'
import PageLoader from '@components/pageLoader'
import { CustomSider, SiderContent } from './sider'
import { Navigate, Outlet } from 'react-router-dom'
import { Header } from 'antd/es/layout/layout'
import { UnorderedListOutlined } from '@ant-design/icons'

const MainLayout: React.FC = () => {
    const [open, setOpen] = useState(false)

    const { isLoading, isLoadingUser, auth, customer } = useAuthenticationContext()
    const screens = Grid.useBreakpoint()

    const showDrawer = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }
    if (isLoading || isLoadingUser) return <PageLoader />

    if (!auth || !customer) return <Navigate to={'/auth/login'} replace />

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {screens.xs ? (
                <Header
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'linear-gradient(180deg, #1C0254 0%, #9461FF 100%)',
                        paddingInline: 10,
                    }}
                >
                    <Button
                        type='text'
                        icon={<UnorderedListOutlined />}
                        style={{ color: '#fff', position: 'absolute', left: 5 }}
                        onClick={showDrawer}
                        size='large'
                    ></Button>
                    <img src='/images/logo-white.png' height={59} />
                </Header>
            ) : (
                <CustomSider />
            )}

            <Layout.Content>
                <Outlet />
            </Layout.Content>
            <Drawer
                width={240}
                placement='left'
                closable={false}
                onClose={onClose}
                open={open}
                bodyStyle={{
                    padding: 0,
                    background:
                        ' linear-gradient(to bottom, rgba(29,5,96,1) 0%, rgba(29,5,96,1) 28%, rgba(99,0,255,1) 100%)',
                    boxShadow:
                        ' 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 3px 4px rgba(0, 0, 0, 0.14)',
                }}
            >
                <SiderContent collapse={onClose} />
            </Drawer>
        </Layout>
    )
}

export default MainLayout
