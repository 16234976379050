import React, { useEffect, useState } from 'react'
import { Button, Space, Form, Input, Col, Row } from 'antd'
import { useAppDispatch } from '@/store/hook'
import { generalActions } from '@/store/slice'
import { useNavigate } from 'react-router-dom'
import Title from 'antd/es/typography/Title'
import fields, { Field } from './fields'
import { useAuthenticationContext } from '@/context/authentication'
import PhoneInput from 'antd-phone-input'
import { updateCustomer } from '@api/customer'
import { ICustomer } from '@/modules/api/interfaces/customer'

export const EditSettingsPage: React.FC = () => {
    const [form] = Form.useForm()
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const dispatch = useAppDispatch()
    const [ownfields, setOwnfields] = useState<Field[]>([])
    const navigate = useNavigate()
    const { customer, getToken, setCustomer } = useAuthenticationContext()

    const fetchStart = () => {
        setLoading(true)
        dispatch(
            generalActions.setLoading({
                isLoading: true,
                mode: 'notification',
                loadingContent: 'Updating Customer',
            }),
        )
    }

    const fetchSuccess = () => {
        setLoading(false)
        dispatch(generalActions.setLoading({ isLoading: false }))
    }

    const fetchError = (error: string) => {
        setLoading(false)
        dispatch(generalActions.setError({ errorMessage: error, mode: 'notification' }))
    }

    const layout = {
        labelCol: {
            xs: { span: 10 },
            sm: { span: 4 },
        },
        wrapperCol: { span: 24 },
    }

    const onFinish = async (values: ICustomer) => {
        fetchStart()
        try {
            const phone: any = values.phone_number as string
            const phoneParse = `${phone?.countryCode}${phone?.areaCode}${phone?.phoneNumber}`
            values['default_daily_limit'] = parseInt(values['default_daily_limit'].toString())
            values['default_tx_limit'] = parseInt(values['default_tx_limit'].toString())

            const phoneRegex = /^\+?[1-9]\d{1,14}$/
            if (!phoneRegex.test(phoneParse)) {
                values['phone_number'] = ''
            } else {
                values['phone_number'] = phoneParse
            }

            const filteredValues = Object.fromEntries(
                Object.entries(values).filter(([_, value]) => value !== null && value !== ''),
            )

            // update data
            const result = await updateCustomer(customer?.id as string, (await getToken())!, {
                ...filteredValues,
            })

            // update current local data with the updated one
            setCustomer({ ...result, 'access_token': customer?.access_token as string })
            localStorage.setItem(
                'customer',
                JSON.stringify({ ...result, 'access_token': customer?.access_token as string }),
            )
            fetchSuccess()
            sendTo('/customer/setting')
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || error.message            
            fetchError(errorMessage || 'Something went wrong')
        }
    }

    const sendTo = (url: string) => {
        navigate(url)
    }

    useEffect(() => {
        setOwnfields(fields)
    }, [])
    return (
        <div className='propins ant-hide-menu-ok'>
            <Space direction='vertical' size={15}>
                <Row>
                    <Col>
                        <Title level={3} style={{ fontWeight: '500' }}>
                            General Settings
                        </Title>
                    </Col>
                </Row>
                <Form layout='horizontal' {...layout} form={form} onFinish={onFinish}>
                    {ownfields.map((x) => (
                        <Col key={x.name} xs={24}>
                            <Form.Item
                                name={x.name}
                                label={x.label}
                                rules={x.rules}
                                labelAlign='left'
                                initialValue={customer?.[x.name]}
                            >
                                {(() => {
                                    switch (x.type) {
                                        case 'text':
                                        case 'email':
                                        case 'number':
                                            return <Input size='large' type={x.type} />

                                        case 'phone':
                                            return <PhoneInput country='sv' size='large' />
                                    }
                                })()}
                            </Form.Item>
                        </Col>
                    ))}

                    <Form.Item>
                        <Space
                            wrap
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                            size={10}
                        >
                            <Button
                                loading={isLoading}
                                type='primary'
                                size='large'
                                htmlType='submit'
                                className='login-form-button'
                            >
                                Save
                            </Button>
                            <Button onClick={() => sendTo('/customer/setting')} size='large' danger>
                                Cancel
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Space>
        </div>
    )
}
export default EditSettingsPage
