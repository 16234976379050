import { useAppDispatch, useAppSelector } from '@/store/hook'
import { generalActions } from '@/store/slice'
import { Button, Result, Row } from 'antd'
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const Page404: React.FC = () => {
    const navigator = useNavigate()
    const dispath = useAppDispatch()
    const { errorMessage } = useAppSelector((state) => state.general)
    if (!errorMessage) return <Navigate to={'/'} replace />
    return (
        <Row justify={'center'}>
            <Result
                status='500'
                title='Error'
                subTitle={errorMessage}
                extra={
                    <Button
                        type='primary'
                        onClick={() => {
                            dispath(generalActions.reset())
                            navigator('/subscription', { replace: true })
                        }}
                    >
                        Back Home
                    </Button>
                }
            />
        </Row>
    )
}
export default Page404
