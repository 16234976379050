import { Rule } from 'antd/es/form';
import { ICustomer } from '@/modules/api/interfaces/customer'

export interface Field{
    type:string
    name:keyof ICustomer
    label:string
    rules?:Rule[]

}

export const fieldsSettings = [
    {
        type:'text',
        name:'ln_host',
        label:'Ln Host',
        rules:[
            { required: true },
            { type: 'url', warningOnly: true },
            { type: 'string', min: 6 }
          
        ]
    },
    {
        type:'text',
        name:'ln_type',
        label:'Ln Type',
        rules:[
            {
                min:0, max:32
            },
            {
                message:'test',
                required:true
            }
        ]
    },
    {
        type:'text',
        name:'main_domain',
        label:'Main Domain',
        rules:[
            {
                min:0, max:32
            },
            {
                message:'test',
                required:true
            }
        ]
    }
]as Field[]

export default fieldsSettings;