import { Row } from 'antd'
import React from 'react'
// import '@/styles/components/qr.less'
import { CopyToClipboard } from './CopyToClipboard'
import ReactQRCode from 'react-qr-code'

export const QRCode = React.forwardRef<HTMLDivElement, Props>(({ content, level = 'L' }, ref) => {
    return (
        <Row className='qr-container' ref={ref}>
            {/* <img alt='icon' src={image} className='qr_currency_icon' width={25} /> */}
            <CopyToClipboard content={content}>
                <ReactQRCode value={content} level={level} />
            </CopyToClipboard>
        </Row>
    )
})
QRCode.displayName = 'QRCode'

interface Props {
    content: string
    image?: string
    level?: string
}
export default QRCode
