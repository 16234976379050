import React from 'react'
import '@/styles/App.less'
import '@/styles/layout.less'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import AppRoutes from '@/routes'
import { Provider } from 'react-redux'
import store from '@/store/store'
import { NotificationProvider } from './context/notification'
import { AuthenticationProvider } from './context/authentication'
import ErrorHandlerLayout from './modules/Layout/handlers/errorHandler'
import LoadingHandlerLayout from './modules/Layout/handlers/loadingHandler'
import { NfcProvider } from './context/nfc'

function App() {
    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#FD7745',
                    },
                }}
            >
                <Provider store={store}>
                    <AuthenticationProvider>
                        <NotificationProvider>
                            <NfcProvider>
                                <BrowserRouter>
                                    <ErrorHandlerLayout>
                                        <LoadingHandlerLayout>
                                                <AppRoutes />
                                        </LoadingHandlerLayout>
                                    </ErrorHandlerLayout>
                                </BrowserRouter>
                            </NfcProvider>
                        </NotificationProvider>
                    </AuthenticationProvider>
                </Provider>
            </ConfigProvider>
        </>
    )
}

export default App
