import { useNotificationContext } from '@/context/notification'
import { Button, Drawer, DrawerProps, Radio, Row } from 'antd'
import React, { useState } from 'react'
import { QrReader } from 'react-qr-reader'

interface DialogQRScannerProps extends DrawerProps {
    onFinish: any
}

const DialogQRScanner: React.FC<DialogQRScannerProps> = ({ onFinish, ...props }) => {
    const { messageApi } = useNotificationContext()
    const [camera, setCamera] = useState('environment')
    return (
        <Drawer placement={'top'} height={'auto'} width={400} headerStyle={{ display: 'none' }} {...props}>
            <Row justify={'center'}>
                <Radio.Group onChange={(e) => setCamera(e.target.value)} value={camera} style={{ marginTop: 10 }}>
                    <Radio.Button value='environment'>Back Camera</Radio.Button>
                    <Radio.Button value='user'>Front Camera</Radio.Button>
                </Radio.Group>
            </Row>
            {props.open && (
                <QrReader
                    onResult={(result) => {
                        if (result && result.getText()) {
                            const text = result.getText()
                            const regx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
                            if (regx.test(text)) {
                                const username = text.split('@').shift()
                                messageApi?.info('Lnaddress read succefully')
                                onFinish(username)
                            }
                        }
                    }}
                    constraints={{ facingMode: camera }}
                />
            )}
            <Button style={{ width: '100%' }} onClick={props.onClose}>
                Close
            </Button>
        </Drawer>
    )
}
export default DialogQRScanner
