import { useAuthenticationContext } from '@/context/authentication'
import { useNotificationContext } from '@/context/notification'
import { useAppSelector } from '@/store/hook'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const key = 'loading'
const LoadingHandlerLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const navigator = useNavigate()
    const [watingMessage, setWatingMessage] = useState(false)
    const { isLoading: authIsLoading } = useAuthenticationContext()
    const {
        isLoading: generalIsLoading,
        redirect,
        detroyAll,
        loadingContent,
    } = useAppSelector((state) => state.general)
    const { messageApi } = useNotificationContext()

    useEffect(() => {
        if (!messageApi) return
        if (authIsLoading) {
            setWatingMessage(true)
            messageApi.open({
                key,
                type: 'loading',
                content: 'Loading...',
                duration: 0,
            })
        }
        if (!authIsLoading && watingMessage) {
            messageApi.destroy(key)
            setWatingMessage(false)
        }
    }, [authIsLoading])

    useEffect(() => {
        if (!messageApi) return
        if (generalIsLoading) {
            setWatingMessage(true)
            messageApi.open({
                key,
                type: 'loading',
                content: loadingContent || 'Loading...',
                duration: 0,
            })
        }
        if (!generalIsLoading && watingMessage) {
            if (detroyAll) {
                messageApi.destroy(key)
            } else {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Done!',
                    duration: 3,
                    onClose() {
                        console.log(redirect)
                        redirect && navigator(redirect, { replace: true })
                    },
                })
            }
            setWatingMessage(false)
        }
    }, [generalIsLoading])

    return <>{children}</>
}

export default LoadingHandlerLayout
