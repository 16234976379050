import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Typography, Col, Row, Button } from 'antd'
import Title from 'antd/es/typography/Title'
import { RightOutlined } from '@ant-design/icons'
import { useAuthenticationContext } from '@/context/authentication'
import fields, { Field } from './fields'

const { Text } = Typography
export const SettingsPage: React.FC = () => {
    const navigate = useNavigate()
    const { customer } = useAuthenticationContext()
    const [ownfields, setOwnfields] = useState<Field[]>([])

    const sendTo = (url: string) => {
        navigate(url)
    }
    useEffect(() => {
        setOwnfields(fields)
    },[])
    return (
        <Card className='propins'>
            <Row className='justify-content-between align-items-center pb-10'>
                <Col>
                    <Title level={3} style={{fontWeight:'500'}} >
                        General Settings
                    </Title>
                </Col>
                <Col>
                    <Button
                        onClick={() => sendTo('edit')}
                        type='text'
                        className='h3 text-info d-flex align-items-center justify-content-center'
                    >
                        Edit <RightOutlined />
                    </Button>
                </Col>
            </Row>
            {ownfields.map((x) => (
                <Row className='mt-2' key={x.name}>
                    <Col flex='180px'>
                        <Text className='text-subtitle-1 font-weight-semibold text-lightText'>{x.label}:</Text>
                    </Col>
                    <Col flex='auto'>
                        <Text className='text-h6 font-weight-regular text-medium-emphasis'>{customer?.[x.name] || 'empty'}</Text>
                    </Col>
                </Row>
            ))}
        </Card>
    )
}
export default SettingsPage
