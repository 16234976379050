import { CheckCircleOutlined, EditOutlined, QrcodeOutlined, StopOutlined } from '@ant-design/icons'
import { ICard, Metadata } from '@api/interfaces/card'
import { Button, Card, Grid, Input, PaginationProps, Row, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DialogCard from './DialogQR'
import { enableCard, getCards } from '@api/card'
import { useNavigate, useParams } from 'react-router-dom'
import { MdArrowBack } from 'react-icons/md'
import { generalActions } from '@/store/slice'
import { useAppDispatch } from '@/store/hook'
import { useNotificationContext } from '@/context/notification'
import UpdateCardDialogForm from './DialogUpdateForm'

type Action = (record: ICard) => React.ReactNode

const columns = (actions: Action): ColumnsType<ICard> => [
    {
        title: 'Name',
        dataIndex: 'card_name',
    },
    {
        title: 'Counter',
        dataIndex: 'counter',
    },
    {
        title: 'Wallet',
        dataIndex: 'wallet_id',
    },
    {
        title: 'UID',
        dataIndex: 'uid',
    },
    {
        title: 'Daily Limit',
        dataIndex: 'daily_limit',
    },
    {
        title: 'Tx Limit',
        dataIndex: 'tx_limit',
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (text) => <>{moment(text).format('lll')}</>,
        sorter: (a, b) => {
            const d1 = moment(a.createdAt)
            const d2 = moment(b.createdAt)
            if (d1.isBefore(d2)) return -1
            if (d1.isAfter(d2)) return 1
            return 0
        },
    },
    {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (_, record) => actions(record),
    },
]

type sortValue = 'desc' | 'asc'
type Sorter = { [x: string]: sortValue }
type Pagination = { page: number; per_page: number }
const CardPage: React.FC = () => {
    const dispatch = useAppDispatch()
    const { messageApi } = useNotificationContext()

    const { walletId } = useParams()
    const screens = Grid.useBreakpoint()
    const navigator = useNavigate()

    const [open, setOpen] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(false)
    const [card, setCard] = useState<ICard>()
    const [cards, setCards] = useState<ICard[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [metadata, setMetadata] = useState<Metadata>()
    const [pagination, setPagination] = useState<Pagination>({ page: 1, per_page: 12 })
    const [sorter, setSorter] = useState<Sorter>({ createdAt: 'asc' })
    const [card_name, setCardName] = useState('')

    const openQr = (data: ICard) => {
        setCard(data)
        setOpen(true)
    }

    const openUpdateDialog = (data: ICard) => {
        setCard(data)
        setOpenUpdate(true)
    }

    const enableBoltcard = async (card: ICard) => {
        try {
            const enabled = card.enable
            await enableCard(card.id, !enabled)
            messageApi?.success(`${enabled ? 'disabled' : 'enabled'} sucessfully`)
            setCards((prev) => {
                return [{ ...card, enable: !enabled }, ...prev.filter((x) => x.id != card.id)]
            })
        } catch (error: any) {
            dispatch(generalActions.setError({ errorMessage: error.message, mode: 'notification' }))
        }
    }

    const onChangePagination: PaginationProps['onChange'] = (page, pageSize) => {
        setPagination({ page, per_page: pageSize })
    }
    const showTotal: PaginationProps['showTotal'] = (total) => `${total} Wallets`

    const Actions = (record: ICard) => (
        <>
            <Button shape='circle' icon={<EditOutlined />} onClick={() => openUpdateDialog(record)} />
            <Button
                shape='circle'
                icon={record.enable ? <StopOutlined /> : <CheckCircleOutlined />}
                onClick={() => enableBoltcard(record)}
            />
            <Button shape='circle' icon={<QrcodeOutlined />} onClick={() => openQr(record)} />
        </>
    )

    useEffect(() => {
        setIsLoading(true)
        const fetchWallets = async () => {
            try {
                const { _metadata, records } = await getCards({
                    ...pagination,
                    ...(card_name ? { card_name } : {}),
                    ...(walletId ? { wallet_id: walletId } : {}),
                    sorter: JSON.stringify(sorter),
                })
                setMetadata(_metadata)
                setCards(records)
            } catch (error) {
                /* empty */
            }
        }
        fetchWallets().finally(() => setIsLoading(false))
        return () => {
            setCards([])
        }
    }, [pagination, sorter])

    return (
        <Card
            hoverable
            style={{ width: '100%', minHeight: '80vh', height: 'auto' }}
            bodyStyle={{ padding: screens.xs ? 5 : 24 }}
        >
            {walletId && (
                <Row justify={'center'} style={{ position: 'relative', minHeight: 40 }}>
                    <Button
                        icon={<MdArrowBack size={20} />}
                        ghost
                        type='ghost'
                        size='large'
                        style={{ position: 'absolute', left: screens.xs ? -10 : 0, top: screens.xs ? 0 : -7 }}
                        onClick={() => navigator(-1)}
                    />
                    <span style={{ maxWidth: '85%', whiteSpace: 'nowrap', textOverflow: 'clip', overflow: 'auto' }}>
                        Cards related to Wallet {walletId}
                    </span>
                </Row>
            )}
            <Input.Search
                // eslint-disable-next-line quotes
                placeholder={"input card's name"}
                allowClear
                enterButton='Search'
                size='large'
                onSearch={(e) => {
                    setPagination({ page: 1, per_page: e ? 1 : 12 })
                    setCardName(e)
                }}
            />
            <Table
                columns={columns(Actions)}
                dataSource={cards}
                rowKey={'id'}
                bordered
                size='small'
                loading={isLoading}
                scroll={{ x: '100%' }}
                style={{ minHeight: 300, marginTop: 13 }}
                onChange={(p, f, sorter) => {
                    if (!Array.isArray(sorter) && sorter?.order) {
                        const value: sortValue = sorter.order == 'ascend' ? 'asc' : 'desc'
                        if (sorter.field == '_count') {
                            setSorter({ cards: value })
                        } else {
                            setSorter({
                                [sorter.field!.toString()]: value,
                            })
                        }
                    }
                }}
                pagination={{
                    pageSize: pagination.per_page,
                    onChange: onChangePagination,
                    total: metadata?.total_count,
                    showSizeChanger: true,
                    showTotal,
                    responsive: true,
                }}
            />
            <DialogCard open={open} onClose={() => setOpen(false)} card={card!} />
            {card && (
                <UpdateCardDialogForm
                    open={openUpdate}
                    onFinishUpdating={(card) => {
                        setOpenUpdate(false)
                        setCards((prev) => {
                            return [{ ...card }, ...prev.filter((x) => x.id != card.id)]
                        })
                    }}
                    card={card}
                />
            )}
        </Card>
    )
}
export default CardPage
