import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { ICustomer } from '@api/interfaces/customer'
import moment, { Moment } from 'moment'
import { authCustomer } from '@api/wallet'
import { CardInstance, WalletInstance } from '@api/axios'
import { InternalAxiosRequestConfig } from 'axios'

interface Auth {
    token: string
    internal_token: string
    createdAt: Moment
}
type callbackFun = () => Promise<void> | void
export interface authenticationContextData {
    isLoadingUser: boolean
    isLoading: boolean
    auth: Auth | null
    getToken: () => Promise<string | null>
    customer: ICustomer | null
    error: string
    setError: (error: string) => void
    setAuth: (user: Auth) => void
    setCustomer: (customer: ICustomer) => void
    signIn: (token: string) => void
    signOut: (cb?: callbackFun) => void
}

const authenticationContextDefaultValue: authenticationContextData = {
    isLoading: false,
    isLoadingUser: false,
    auth: null,
    getToken: async () => null,
    customer: null,
    error: '',
    setError: () => null,
    setCustomer: () => null,
    setAuth: () => null,
    signIn: () => null,
    signOut: () => null,
}
export const authenticationContext = createContext<authenticationContextData>(authenticationContextDefaultValue)

export const AuthenticationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [auth, setAuth] = useState<Auth | null>(null)
    const [customer, setCustomer] = useState<ICustomer | null>(null)
    const [isLoadingUser, setLoadingUser] = useState(true)
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchStart = () => {
        setLoading(true)
        setError('')
    }

    const fetchSuccess = () => {
        setLoading(false)
        setError('')
    }

    const fetchError = (error: string) => {
        setLoading(false)
        setError(error)
    }
    const signIn = async (token: string) => {
        fetchStart()
        console.log('signing')
        try {
            const data = await authCustomer(token)
            data.customer.access_token = token
            if (data.valid && data.customer) {
                const auth = {
                    token: data.token,
                    internal_token: token,
                    createdAt: moment(),
                }
                setAuth(auth)
                setCustomer(data.customer)
                localStorage.setItem('customer', JSON.stringify(data.customer))
                localStorage.setItem('auth', JSON.stringify(auth))
                fetchSuccess()
                return data.token
            } else {
                fetchError(data.message || 'error login')
            }
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || error.message
            fetchError(errorMessage)
        }
    }
    const signOut = (cb?: callbackFun) => {
        localStorage.removeItem('customer')
        localStorage.removeItem('auth')
        setAuth(null)
        setCustomer(null)
        if (cb) cb()
    }
    const getToken = async () => {
        if (!auth || !customer) return null
        if (moment(auth.createdAt).add(1, 'hour').isBefore(moment())) {
            console.log('token has expired')
            const token = await signIn(customer.access_token)
            return token || ''
        }
        return auth.token
    }
    async function injectInterceptor(req: InternalAxiosRequestConfig) {
        const token = await getToken()
        req.headers.Authorization = `Bearer ${token}`
        return req
    }

    useEffect(() => {
        const setdata = async () => {
            const customer = localStorage.getItem('customer')
            const auth = localStorage.getItem('auth')
            if (customer && auth) {
                const parsedCustomer = JSON.parse(customer) as ICustomer
                const parsedAuth = JSON.parse(auth) as Auth
                if (parsedCustomer && parsedAuth && parsedCustomer.access_token) {
                    if (moment(parsedAuth.createdAt).add(1, 'hour').isBefore(moment())) {
                        console.log('token has expired')
                        await signIn(parsedCustomer.access_token)
                    } else {
                        setAuth(parsedAuth)
                        setCustomer(parsedCustomer)
                    }
                }
            }
        }

        setdata().then(() => setLoadingUser(false))
    }, [])
    useEffect(() => {
        if (!auth) return
        const cardReqInt = CardInstance.interceptors.request.use(injectInterceptor)
        const walletReqInt = WalletInstance.interceptors.request.use(injectInterceptor)
        return () => {
            CardInstance.interceptors.request.eject(cardReqInt)
            WalletInstance.interceptors.request.eject(walletReqInt)
        }
    }, [auth])
    return (
        <authenticationContext.Provider
            value={{
                isLoading,
                isLoadingUser,
                customer,
                auth,
                getToken,
                error,
                setError,
                setAuth,
                setCustomer,
                signIn,
                signOut,
            }}
        >
            {children}
        </authenticationContext.Provider>
    )
}

export const useAuthenticationContext = () => {
    return useContext(authenticationContext)
}
