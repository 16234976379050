import { AuthResponse } from './interfaces/customer'
import { WalletResponse } from './interfaces/wallet'
import { WalletInstance } from './axios'

interface WalletsProps {
    page: number
    per_page: number
    username?: string
    batch_id?: string
    sorter?: string
}

export const authCustomer = async (accessToken: string) => {
    const x = await WalletInstance.post<AuthResponse>('/auth/customer', { accessToken })
    return x.data
}

export const getWallets = async ({ ...props }: WalletsProps) => {
    const res = await WalletInstance.get<WalletResponse>('/wallet', {
        params: { ...props },
      
    })
    return res.data
}
export default { getWallets }
