import React from 'react'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'

const NotPageFound: React.FC = () => {
    const navigator = useNavigate()
    return (
        <Result
            status='404'
            title='No page found'
            subTitle='Sorry, the page you visited does not exist.'
            extra={
                <Button
                    type='primary'
                    onClick={() => {
                        navigator('/', { replace: true })
                    }}
                >
                    Back Home
                </Button>
            }
        />
    )
}
export default NotPageFound
