import React from 'react'
import { Row, Spin } from 'antd'

export const PageLoader: React.FC= () => {
    return (
    <Row justify={'center'} style={{ margin: 'auto' }}>
        <Spin tip='Loading' size='large'>
            <div className='content-spin' />
        </Spin>
    </Row>
)}
export default PageLoader
