import React from 'react'
import '@/styles/components/chip.less'

export type ChipProps = {
    label: string
    color?: string
}
export const Chip: React.FC<ChipProps> = ({ label, color }) => {
    return (
        <div className='tk-chip' style={{ backgroundColor: color }}>
            <span>{label}</span>
        </div>
    )
}
export default Chip
