import { Rule } from 'antd/es/form'
import { ICustomer } from '@/modules/api/interfaces/customer'

export interface Field {
    type: string
    name: keyof ICustomer
    label: string
    rules?: Rule[]
}

export const fieldsSettings = [
    {
        type: 'text',
        name: 'owner_name',
        label: 'Owner Name',
        rules: [
            {
                message: 'Owner Name is required',
                required: true,
            },
        ],
    },
    {
        type: 'text',
        name: 'name',
        label: 'Name',
        rules: [
            {
                message: 'Name is required',
                required: true,
            },
        ],
    },
    {
        type: 'email',
        name: 'email',
        label: 'Email',
        rules: [
            {
                type: 'email',
                message: 'The input is not valid E-mail!',
            },
            {
                required: false,
                message: 'Please input your E-mail!',
            },
        ],
    },
    {
        type: 'phone',
        name: 'phone_number',
        label: 'Phone Number',
        
    },
    {
        type: 'text',
        name: 'location',
        label: 'Location',
    },
    {
        type: 'number',
        name: 'default_daily_limit',
        label: 'Default Daily Limit',
        rules: [
            {
                pattern: /^(10|[1-9]\d{3,9})$/,
                message: 'The value min is 1000 and max 1000000000',
                required: true,
            }
        ],
    },
    {
        type: 'number',
        name: 'default_tx_limit',
        label: 'Default Tx Limit',
        rules: [
            {
                pattern: /^(10|[1-9]\d{1,9})$/,
                message: 'The value min is 10 and max 1000000000',
                required: true,
            }
        ],
    },
] as Field[]

export default fieldsSettings
