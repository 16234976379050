import React, { useEffect, useState } from 'react'
import { Button, Space, Form, Input, Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import Title from 'antd/es/typography/Title'
import fields, { Field } from './fields'
import { useAuthenticationContext } from '@/context/authentication'

export const EditServerPage: React.FC = () => {
    const [form] = Form.useForm()
    const [ownfields, setOwnfields] = useState<Field[]>([])
    const navigate = useNavigate()
    const { customer } = useAuthenticationContext()

    const layout = {
        labelCol: {
            xs: { span: 10 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    }
    const onFinish = (values: any) => {
        // do nothing
    }

    const sendTo = (url: string) => {
        navigate(url)
    }

    useEffect(() => {
        setOwnfields(fields)
    },[])
    return (
        <div className='propins ant-hide-menu-ok'>
            <Space direction='vertical' size={15}>
                <Row>
                    <Col>
                        <Title level={3} style={{ fontWeight: '500' }}>
                            Server Settings
                        </Title>
                    </Col>
                </Row>
                <Form layout='horizontal' {...layout} form={form} onFinish={onFinish}>
                    {ownfields.map((x) => (
                        <Col key={x.name} xs={24}>
                            <Form.Item
                                name={x.name}
                                label={x.label}
                                rules={x.rules}
                                labelAlign='left'
                                initialValue={customer?.[x.name]}
                            >
                                {(() => {
                                    return <Input disabled size='large' type={x.type} />
                                })()}
                            </Form.Item>
                        </Col>
                    ))}

                    <Form.Item>
                        <Space
                            wrap
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                            size={10}
                        >
                            <Button
                                type='primary'
                                disabled
                                size='large'
                                htmlType='submit'
                                className='login-form-button'
                            >
                                Save
                            </Button>
                            <Button onClick={() => sendTo('/customer/server')} size='large' danger>
                                Cancel
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Space>
        </div>
    )
}
export default EditServerPage
