import { CreditCardOutlined, QrcodeOutlined, SelectOutlined } from '@ant-design/icons'
import { ICard } from '@api/interfaces/card'
import { IWallet, Metadata } from '@api/interfaces/wallet'
import Chip from '@components/Chip'
import { Button, Card, Divider, Input, PaginationProps, Row, Space, Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DialogCard from '../card/DialogQR'
import CreateCardDialogForm from '../card/DialogCreationForm'
import { getWallets } from '@api/wallet'
import DialogQRScanner from './QRScanner'
import { useNavigate } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

const columns = (actions: { create: any; list: any; batch: any }): ColumnsType<IWallet> => [
    {
        title: 'ID',
        dataIndex: 'id',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
    },
    {
        title: 'Username',
        dataIndex: 'username',
        sorter: true,
    },
    {
        title: 'LnAddress',
        dataIndex: 'ln_address',
    },
    {
        title: 'Batch ID',
        dataIndex: 'batch_id',
        render(value) {
            return value ? (
                <Button type='link' onClick={() => actions.batch(value)}>
                    {value}
                </Button>
            ) : (
                ''
            )
        },
    },
    {
        title: 'Type',
        dataIndex: 'source_type',
        sorter: true,
    },
    {
        title: 'Boltcard Enable',
        dataIndex: '_count',
        align: 'center',
        sorter: true,
        render: (_count) => <Chip label={_count.cards} color={_count.cards > 0 ? '#4CAF50' : '#607d8b'} />,
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        sorter: true,
        render: (text) => <>{moment(text).format('lll')}</>,
    },
    {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (_, record) => (
            <>
                <Space size={10} >
                    <Button icon={<SelectOutlined />} onClick={() => actions.create(record)} >Create</Button>
                    <Button icon={<CreditCardOutlined />}  onClick={() => actions.list(record)} >Related to.</Button>
                </Space>
            </>
        ),
    },
]

type sortValue = 'desc' | 'asc'
type Sorter = { [x: string]: sortValue }
type Pagination = { page: number; per_page: number }

const WalletPage: React.FC = () => {
    const navigator = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [wallets, setWallets] = useState<IWallet[]>([])
    const [metadata, setMetadata] = useState<Metadata>()
    const [pagination, setPagination] = useState<Pagination>({ page: 1, per_page: 30 })
    const [sorter, setSorter] = useState<Sorter>({ cards: 'desc' })

    const [username, setUsername] = useState('')
    const [card, setCard] = useState<ICard>()
    const [selectedWallet, setSelectedWallet] = useState<IWallet | null>(null)

    const [openForm, setOpenForm] = useState(false)
    const [openCD, setOpenCD] = useState(false)
    const [scanQR, setScanQR] = useState(false)

    const handleCreateCard = (data: IWallet) => {
        setSelectedWallet(data)
        setOpenForm(true)
    }
    const handleListCards = (data: IWallet) => {
        navigator(`/wallet/${data.id}/cards`)
    }
    const handleBatch = (batchId: string) => {
        navigator(`/wallet/batch/${batchId}/printJson`)
    }

    const onChangePagination: PaginationProps['onChange'] = (page, pageSize) => {
        setPagination({ page, per_page: pageSize })
    }
    const showTotal: PaginationProps['showTotal'] = (total) => `${total} Wallets`

    useEffect(() => {
        setIsLoading(true)
        const fetchWallets = async () => {
            try {
                const { _metadata, records } = await getWallets({
                    ...pagination,
                    ...(username ? { username } : {}),
                    sorter: JSON.stringify(sorter),
                })
                setMetadata(_metadata)
                setWallets(records)
                if (records.length == 1 && records[0]._count.cards == 0) {
                    setSelectedWallet(records[0])
                    setOpenForm(true)
                }
            } catch (error) { /* empty */ }
        }
        fetchWallets().finally(() => setIsLoading(false))
        return () => {
            setWallets([])
        }
    }, [pagination, sorter])

    return (
        <Card hoverable style={{ width: '100%', minHeight: '80vh', height: 'auto' }}>
            <Row wrap={false}>
                <Input.Search
                    placeholder='input your username'
                    allowClear
                    enterButton='Search'
                    size='large'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onSearch={(e) => {
                        setPagination({ page: 1, per_page: e ? 1 : 30 })
                    }}
                />

                <Button
                    type='primary'
                    ghost
                    icon={<QrcodeOutlined style={{ fontSize: 25 }} />}
                    style={{ padding: isMobileOnly ? '0 20px' : '5px 10px', width: 'auto', height: 'auto' }}
                    onClick={() => setScanQR((prev) => !prev)}
                />
            </Row>
            <Table
                columns={columns({ create: handleCreateCard, list: handleListCards, batch: handleBatch })}
                dataSource={wallets}
                rowKey={'id'}
                bordered
                size='small'
                loading={isLoading}
                scroll={{ x: '100%' }}
                style={{ minHeight: 300, marginTop: 13 }}
                onChange={(p, f, sorter) => {
                    if (!Array.isArray(sorter) && sorter?.order) {
                        const value: sortValue = sorter.order == 'ascend' ? 'asc' : 'desc'
                        if (sorter.field == '_count') {
                            setSorter({ cards: value })
                        } else {
                            setSorter({
                                [sorter.field!.toString()]: value,
                            })
                        }
                    }
                }}
                pagination={{
                    pageSize: pagination.per_page,
                    onChange: onChangePagination,
                    total: metadata?.total_count,
                    showSizeChanger: true,
                    showTotal,
                    responsive: true,
                }}
            />
            {selectedWallet && (
                <CreateCardDialogForm
                    open={openForm}
                    onClose={() => {
                        setOpenForm(false)
                        setSelectedWallet(null)
                    }}
                    wallet={selectedWallet}
                    onFinishCreating={(card) => {
                        setSelectedWallet(null)
                        setCard(card)
                        setOpenForm(false)
                        setOpenCD(true)
                    }}
                />
            )}
            <DialogQRScanner
                open={scanQR}
                onFinish={(username: string) => {
                    setPagination({ page: 1, per_page: 1 })
                    setUsername(username)
                    setScanQR(false)
                }}
                onClose={() => setScanQR(false)}
            />
            <DialogCard open={openCD} onClose={() => setOpenCD(false)} card={card!} creation />
        </Card>
    )
}

export default WalletPage
