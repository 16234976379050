import { useAppDispatch } from '@/store/hook'
import { generalActions } from '@/store/slice'
import { updateCard } from '@api/card'
import { ICard } from '@api/interfaces/card'
import { Button, Drawer, DrawerProps, Form, Input, Space } from 'antd'
import { Rule } from 'antd/es/form'
import React from 'react'

type Field = {
    name: string
    label: string
    type: string
    rules: Rule[]
}
const fields: Field[] = [
    {
        name: 'card_name',
        label: 'Card Name',
        type: 'text',
        rules: [{ min: 4, max: 20 }],
    },
    {
        name: 'tx_limit',
        label: 'Transaction Limit(Sats)',
        type: 'number',
        rules: [
            {
                type: 'number',
                transform(value) {
                    return Number(value)
                },
            },
        ],
    },
    {
        name: 'daily_limit',
        label: 'Daily Limit(Sats)',
        type: 'number',
        rules: [
            {
                type: 'number',
                transform(value) {
                    return Number(value)
                },
            },
        ],
    },
]

interface DialogCardProps extends DrawerProps {
    card: ICard
    onFinishUpdating: (card: ICard) => any
}

const UpdateCardDialogForm: React.FC<DialogCardProps> = ({ card, onFinishUpdating, ...props }) => {
    const dispatch = useAppDispatch()

    const [form] = Form.useForm()

    const onFinish = async (values: any) => {
        try {
            dispatch(
                generalActions.setLoading({
                    isLoading: true,
                    mode: 'notification',
                    loadingContent: 'Creating card',
                }),
            )
            const updatedCard = await updateCard(card.id, {
                card_name: values.card_name,
                daily_limit: Number(values.daily_limit),
                tx_limit: Number(values.tx_limit),
            })
            console.log(updatedCard)
            dispatch(generalActions.setLoading({ isLoading: false }))
            onFinishUpdating(updatedCard)
        } catch (error: any) {
            dispatch(generalActions.setError({ errorMessage: error.message, mode: 'notification' }))
        }
    }

    const onReset = () => {
        form.resetFields()
    }

    return (
        <Drawer placement={'top'} height={'auto'} width={400} title='Update Card' {...props}>
            <Form
                form={form}
                layout='vertical'
                name='card-form'
                onFinish={onFinish}
                style={{ width: '100%' }}
                initialValues={{
                    card_name: card.card_name,
                    tx_limit: card.tx_limit,
                    daily_limit: card.daily_limit,
                }}
            >
                {fields.map((x) => (
                    <Form.Item key={x.name} name={x.name} label={x.label} rules={x.rules}>
                        <Input type={x.type} />
                    </Form.Item>
                ))}
                <Form.Item>
                    <Space>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                        <Button htmlType='button' onClick={onReset}>
                            Reset
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
export default UpdateCardDialogForm
